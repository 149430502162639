<template>
  <v-card>
    <v-card-title>Aprovar Orçamento</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="projetos"
            item-text="projeto.nome"
            v-model="projeto"
            label="Projeto"
            outlined
            dense
            return-object
            @change="findOrcamentos(projeto.projeto.id)"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels v-if="orcamento.orcamentoId">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Projeto</th>
                        <th class="text-center">Data Início</th>
                        <th class="text-center">Data Fim</th>
                        <th class="text-center"># Orçamento</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Aceito</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ projeto.projeto.nome }}</td>
                        <td class="text-center">
                          {{ projeto.projeto.dataInicio | localDateToString }}
                        </td>
                        <td class="text-center">
                          {{ projeto.projeto.dataFim | localDateToString }}
                        </td>
                        <td class="text-center">{{ orcamento.orcamentoId }}</td>
                        <td class="text-right">
                          <b> {{ orcamento.valorCliente | formataMoeda }} </b>
                        </td>
                        <td class="text-right">
                          <v-switch
                            v-model="orcamento.aceito"
                            color="success darken-2"
                            @change="aceitar(orcamento.aceito)"
                            :disabled="orcamento.aceito"
                          >
                          </v-switch>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Atividade</th>
                        <th class="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in orcamento.orcamentoFornecedores"
                        :key="index"
                      >
                        <td>
                          {{ item.atividade }}
                        </td>
                        <td class="text-right">
                          <b> {{ item.valorCliente | formataMoeda }} </b>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Projeto from "@/service/projeto";
import Orcamento from "@/service/orcamento";
import { mapGetters } from "vuex";
export default {
  name: "AprovarOrcamento",
  data() {
    return {
      projetos: [],
      projeto: {},
      orcamento: { aceito: "" },
    };
  },
  computed: {
    ...mapGetters(["getAuth"]),
  },
  methods: {
    async findProjetos() {
      const { cliente } = this.getAuth;
      this.projetos = await Projeto.fetchProjetosPorCliente({
        clienteId: cliente.id,
      });
    },
    async findOrcamentos(projetoId) {
      this.orcamento = await Orcamento.fetchOrcamentosCliente({
        projetoId: projetoId,
      });
    },
    async aceitar(aceito) {
      console.log(aceito);
      try {
        await Orcamento.aceito({
          orcamentoId: this.orcamento.orcamentoId,
          aceito: aceito,
        });
        this.$notify({
          title: "Sucesso",
          text: "Orçamento aceito!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "Erro",
          text: "Descupe! ocorreu um erro no sistema",
          type: "success",
        });
        this.orcamento.aceito = false;
      }
    },
  },
  created() {
    this.findProjetos();
  },
};
</script>

<style>
</style>