import http from "./http"


const Projeto = {

  fetchProjetos: async (payload) => {
    return http({
      method: "GET",
      url: `projetos`,
      params: payload
    })
      .then((resp) => {
        return resp.data
      })
      .catch((error) => error.response.data)
  },

  fetchProjetosPorCliente: async (payload) => {
    return http({
      method: "GET",
      url: `projetos/cliente`,
      params: payload
    })
      .then((resp) => {
        return resp.data
      })
      .catch((error) => error.response.data)
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `projetos`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `projetos`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  finalizar: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "PUT",
        url: `projetos/finalizar`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

}

export default Projeto;