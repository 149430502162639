import http from "./http";

const orcamento = {
  fetchOrcamentosProjeto: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `orcamentos/projeto`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  fetchOrcamentosCliente: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `orcamentos/cliente`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `orcamentos`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  aceito: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "PUT",
        url: `orcamentos/aceito`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `orcamentos`,
        data: { ...payload },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default orcamento;
